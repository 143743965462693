import * as React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  headerTitle,
  inputFormContainer,
  walnutPrimaryButton,
} from "../components/layout.module.css";
import Layout from "../components/layout";
import { navigate, PageProps } from "gatsby";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { VerifiedProductItem } from "./index";
import { ErrorMessage } from "@hookform/error-message";
import { FormCheck } from "react-bootstrap";

export interface IIFormInput {
  term1: boolean;
  term2: boolean;
}

interface LocationState {
  productState: VerifiedProductItem;
}
const isBrowser = typeof window !== "undefined";
const DisclaimerPage = ({ location }: PageProps) => {
  const stateData = location.state as LocationState;

  if (stateData) {
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<IIFormInput>();

    const onSubmit: SubmitHandler<IIFormInput> = (data) => {
      navigate("/input-form/", {
        state: { productState: stateData.productState, termState: data },
      });
    };

    return (
      <Layout>
        <div className={inputFormContainer}>
          <h5 className={headerTitle}>
            Welcome to Eldon Healthcare e-Warranty Registration
          </h5>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Check type="checkbox" id="form-term-checkbox-1">
              <Controller
                name="term1"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "You must agreed to proceed",
                  },
                }}
                render={({ field }) => (
                  <FormCheck.Input
                    isInvalid={errors.term1 != null}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    ref={field.ref}
                    value={String(field.value)}
                  ></FormCheck.Input>
                )}
              />
              <FormCheck.Label>
                <p style={{ textAlign: "justify" }}>
                  I acknowledge and agree that successful registration does not
                  guarantee warranty claim upon submission of the warranty
                  registration. The validity of the warranty is contingent on
                  the legitimacy of the uploaded receipt which will be verified
                  upon claim.The decision on warranty claim is at the sole
                  discretion of Eldon Healthcare Sdn Bhd. The following
                  conditions are not claimable:
                  <ol>
                    <li>Product failure due to intentional damage.</li>
                    <li>Product alteration.</li>
                    <li>Wear and tear of accessories.</li>
                  </ol>
                </p>
              </FormCheck.Label>
              <ErrorMessage
                errors={errors}
                name="term1"
                render={({ message }) => (
                  <Form.Control.Feedback type="invalid">
                    {message}
                  </Form.Control.Feedback>
                )}
              />
            </Form.Check>
            <br />
            <Form.Check type="checkbox" id="form-term-checkbox-2">
              <Controller
                name="term2"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "You must agreed to proceed",
                  },
                }}
                render={({ field }) => (
                  <FormCheck.Input
                    isInvalid={errors.term2 != null}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    ref={field.ref}
                    value={String(field.value)}
                  ></FormCheck.Input>
                )}
              />
              <FormCheck.Label>
                <p style={{ textAlign: "justify" }}>
                  I agree to the{" "}
                  <a href="https://walnut.asia/terms-of-use/" target={"_blank"}>
                    <strong>terms of use</strong>
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://walnut.asia/privacy-policy/"
                    target={"_blank"}
                  >
                    <strong>privacy policy</strong>
                  </a>{" "}
                  upon registering the warranty for the product.
                </p>
              </FormCheck.Label>
              <ErrorMessage
                errors={errors}
                name="term2"
                render={({ message }) => (
                  <Form.Control.Feedback type="invalid">
                    {message}
                  </Form.Control.Feedback>
                )}
              />
            </Form.Check>

            <div className="d-grid mt-5">
              <Button type="submit" size="lg" className={walnutPrimaryButton}>
                NEXT
              </Button>
            </div>
          </Form>
        </div>
      </Layout>
    );
  } else {
    if (isBrowser) {
      navigate("/", { replace: true });
    }
    return <Layout></Layout>;
  }
};

export default DisclaimerPage;
